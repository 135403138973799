import React from "react"
import { graphql } from "gatsby"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"

const Page = ({data, pageContext}) => {

  return (
    <>
      <Seo title='Рецепти' />

      <Layout>
        <SimpleBanner title='Рецепти'>
        </SimpleBanner>
        <div className="section">
          <div className="feed">{
            // allProduct.map((node, index) => {
            //   return <RecipeItem key={index} feature={node} />
            // })
          }</div>
        </div>
      </Layout>
    </>
  )
}
 
export default Page

export const query = graphql`
query (
  $limit: Int
  $skip: Int
  ) {
  allPrismicRecipe(
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        data {
          name {
            text
          }
        }
      }
    }
  }
}
`
